import React from 'react';
import { LayoutProvider } from '../../components/layout';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { StackOnBreadcrumbs } from '../../atoms';
import { useViewPortSize } from '../../hooks';
import { ResponsiveContainer } from '../../atoms/Containers';
import { CustomerServicePage } from '../../components/sections';
import { stackStyled } from '../../theme';

export interface IDataCards {
  id: number;
  title: string;
  action: string;
  href: string;
  icon: {
    url: string;
    ext: string;
  };
}

interface IData {
  strapiCustomerService: {
    title: string;
    description: string;
    cards: Array<IDataCards>;
  };
}

const query = graphql`
  query CustomerService {
    strapiCustomerService {
      title
      description
      cards {
        id
        title
        action
        href
        icon {
          url
          ext
        }
      }
    }
  }
`;

const BreadCrumbsContainer = stackStyled('div', (props: { isMobile: boolean }) => ({
  position: 'absolute',
  top: props.isMobile ? '10px' : '30px',
  left: props.isMobile ? '16px' : '3%',
  textAlign: 'left',
  display: 'flex',
}));

const MainWrapper = stackStyled('div', (props: { isMobile: boolean; isTablet?: boolean }) => ({
  display: 'flex',
  maxWidth: '1280px',
  width: props.isMobile ? '95%' : props.isTablet ? '90%' : '100%',
  justifyContent: 'center',
  padding: props.isMobile ? '50px 0px 0px 0px' : '50px 0px 0px 0px',
  marginLeft: 'auto',
  marginRight: 'auto',
  overflowX: 'hidden',
  textAlign: 'center',
  minHeight: 'auto',
}));

const CustomerService: React.FC<PageProps> = (props: PageProps) => {
  const data = useStaticQuery<IData>(query);
  const [isMobile, isTablet] = useViewPortSize();

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet}>
        <ResponsiveContainer>
          {/* <BreadCrumbsContainer isMobile={isMobile}>
            <StackOnBreadcrumbs
              pages={[
                { title: 'Home', href: '/' },
                { title: 'Customer service', href: '/customer-service' },
              ]}
            />
          </BreadCrumbsContainer> */}
          <CustomerServicePage
            title={data.strapiCustomerService.title}
            description={data.strapiCustomerService.description}
            cards={data.strapiCustomerService.cards}
          />
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default CustomerService;
